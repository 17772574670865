// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-portfolio-portfolio-component-jsx": () => import("./../../../src/templates/portfolio/portfolio.component.jsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-component-jsx" */),
  "component---src-templates-post-post-component-jsx": () => import("./../../../src/templates/post/post.component.jsx" /* webpackChunkName: "component---src-templates-post-post-component-jsx" */)
}

